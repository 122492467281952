@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600;700&display=swap');

@supports (-webkit-touch-callout: none) {
    .h-screen {
    height: -webkit-fill-available;
    }
}

html{
    font-family: 'Barlow Semi Condensed', sans-serif;
    letter-spacing: .0625rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;